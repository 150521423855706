<template>
  <v-container v-if="uploads.id" fluid>
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-list flat>
              <v-list-item-title>
                <h3> Dettagli Import </h3>
              </v-list-item-title>
              <ListItem subtitle="Tipo import">
                {{ uploads.type }}
              </ListItem>
              <ListItem subtitle="Nome del file">
                {{ uploads.filename }}
              </ListItem>
              <ListItem subtitle="Stato">
                {{
                  uploads.status === 'pending'
                    ? 'In corso'
                    : uploads.status === 'done'
                    ? 'Completato'
                    : 'Errore'
                }}
              </ListItem>
              <ListItem subtitle="Totale">
                {{ uploads.num_records }}
              </ListItem>
              <ListItem subtitle="Processati">
                {{ uploads.processed }}
              </ListItem>
              <ListItem subtitle="Errori">
                {{ uploads.error }}
              </ListItem>
            </v-list>
          </v-col>
          <v-col>
            <UploadErrorsTable />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import UploadErrorsTable from '@components/uploads/UploadErrorsTable.vue'
import ListItem from '@components/structure/lists/ListItem.vue'

export default {
  name: 'ErrorDetails',
  components: {
    ListItem,
    UploadErrorsTable,
  },
  computed: {
    ...authComputed,
    ...mapState('uploads', {
      uploads: 'current',
    }),
  },
  mounted() {
    this.setCurrent(this.$route.params.id)
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions('uploads', ['setCurrent']),
    ...mapMutations('uploads', {
      reset: 'RESET_CURRENT',
    }),
  },
}
</script>

<style scoped></style>
