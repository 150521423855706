<template>
  <v-list-item :two-line="!!subtitle">
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">
        <slot />
      </v-list-item-title>
      <v-list-item-subtitle v-if="subtitle"
        >{{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    subtitle: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped></style>
