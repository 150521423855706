<template>
  <v-container fluid class="px-lg-10">
    <BaseTable
      title="Lista errori"
      module="uploadsError"
      :headers="headers"
      :elevation="0"
      :actions="actions"
      sortby="id"
    >
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title> Dettagli dati </v-card-title>
        <v-card-text>
          <UploadsErrorForm v-if="dialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import UploadsErrorForm from '@components/uploads/UploadsErrorForm.vue'

import { createHelpers } from 'vuex-map-fields'

const { mapFields: mapErrorFilters } = createHelpers({
  getterType: 'uploadsError/getFiltersFields',
  mutationType: 'uploadsError/SET_FILTER_FIELDS',
})

export default {
  name: 'UploadErrorsTable',
  components: { BaseTable, UploadsErrorForm },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Messagio Errore',
          value: 'message',
        },
      ],
      actions: [
        {
          label: 'mostra dati',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          onItemCondition: () => this.canUser('upload_errors', 'read'),
          handler: this.openModal,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('uploads', ['current']),
    ...mapErrorFilters(['id_import', 'id']),
  },
  mounted() {
    // Set filters for errors to the current upload
    this.id_import = this.current.id
  },
  beforeDestroy() {
    this.resetFilters()
  },
  methods: {
    ...mapMutations('uploadsError', {
      resetFilters: 'RESET_FILTER_FIELDS',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>

<style scoped></style>
