<template>
  <v-simple-table dense>
    <template v-slot>
      <thead>
        <tr>
          <th class="text-left"> Intestazione </th>
          <th class="text-left"> Valore </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in computedContent" :key="item[0]">
          <td>{{ item[0] }}</td>
          <td>{{ item[1] }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { flow, head, method } from 'lodash'

const { mapFields } = createHelpers({
  getterType: 'uploadsError/getCurrent',
  mutationType: 'uploadsError/SET_CURRENT_FIELDS',
})

export default {
  name: 'UploadsErrorForm',
  components: {},
  computed: {
    ...mapFields(['content']),
    computedContent() {
      const origin = this.content || {}
      const asPairs = Object.entries(origin)
      const sorted = asPairs.sort((a, b) => {
        const getValue = flow([head, method('charCodeAt', 0)])
        return getValue(a) - getValue(b)
      })
      return sorted
    },
  },
}
</script>

<style scoped></style>
